import Typography from '../../atoms/typography'
import { styled } from '@mui/system'
import { Grid as MuiGrid } from '@mui/material'
import Button from '../../molecules/button'

const shouldForwardProp = (prop) => !['noParallax'].includes(prop)

const Root = styled('div', { shouldForwardProp })`
	display: flex;
	flex-direction: column;
	${({ theme }) => theme.breakpoints.up('md')} {
		margin-left: 0;
	}
`

const Title = styled(Typography)`
	line-height: 1.1;
	font-size: ${({ theme }) => theme.typography.pxToRem(18)};
`

const Subtitle = styled(Typography)`
	line-height: 1.1;
	margin-top: 5px;
`

const Description = styled(Typography)`
	line-height: 1.1;
	margin-top: 5px;
`

const ImageColumn = styled('div')`
	width: 100%;
`

const TextColumn = styled('div')`
	display: flex;
	justify-content: space-between;
	padding: calc(var(--spacing-1) * 1.3) 0 0 0;
	flex-direction: column;
	align-items: inherit;

	${({ theme }) => theme.breakpoints.up('md')} {
		align-items: flex-start;
		padding: calc(var(--spacing-1) * 1.5) 0 calc(var(--spacing-1) * 1.4) 4;
	}
`

const CtaContainer = styled(MuiGrid)`
	gap: 22px;
	margin-top: 10px;
	margin-bottom: 8px;
	text-align: left;
	justify-content: flex-start;
	${({ theme }) => theme.breakpoints.up('md')} {
		gap: 38px;
		margin-bottom: 0;
	}
	.MuiTypography-root {
		font-weight: normal;
		border: 0;
		text-decoration: underline;
	}
`

const ButtonStyled = styled(Button)``

export { Title, Root, ImageColumn, TextColumn, CtaContainer, ButtonStyled, Subtitle, Description }
